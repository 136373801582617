// Customizable Area Start
import React, { PureComponent } from 'react'

export interface Props {
  createRoomModal: boolean;
  userCoinAmount: number;
  selectedValues: any;
  userProfileData: any;
  setUserProfileData: (userData: any) => void;
  setCoin: (coin: number, refill: boolean) => void;
  getUserYCoins: () => number;
  changeCreateRoomModal: (condition: boolean) => void;

  // Dashboard turtorials
  tutorials: { data: any[], count: number, isOpen: boolean };
  setTutorials: (data: any[], onEnd: () => void, isOpen?: boolean) => void;
  isTutorialStart?: boolean
  onNextTutorial: () => void;
  highlight?: {
    position: string,
    zIndex: number,
    backgroundColor: string,
  },
  scrollStatus?: boolean,
  setScrollStatus?: (status: boolean) => void,
}

const StateContext = React.createContext<Props>({
  createRoomModal: false,
  userCoinAmount: 0,
  selectedValues: {},
  userProfileData: {},
  setUserProfileData: (userData: any) => { },
  setCoin: (coin: number, refill: boolean) => { },
  getUserYCoins: () => 0,
  changeCreateRoomModal: (condition: boolean) => { },

  // Dashboard turtorials
  tutorials: { data: [], count: 0, isOpen: false },
  setTutorials: (data: any[], onEnd: () => void, isOpen?: boolean) => { },
  isTutorialStart: false,
  onNextTutorial: () => { },
  highlight: {
    position: 'relative',
    zIndex: 99999,
    backgroundColor: 'white',
  },
  scrollStatus: false,
  setScrollStatus: () => {},
});

interface CProps { }
interface State {
  createRoomModal: boolean;
  userCoinAmount: number;
  userProfileData: any;
  selectedValues: any;
  tutorials: { data: any[], count: number, isOpen: boolean, callOnEnd: () => void };
  isTutorialStart?: boolean,
  highlight?: {
    position: string,
    zIndex: number,
    backgroundColor: string,
  },
  scrollStatus: boolean;
}

export class StateProvider extends PureComponent<CProps, State> {
  constructor(props: CProps) {
    super(props);

    // State for data
    this.state = {
      createRoomModal: false,
      userCoinAmount: 0,
      userProfileData: {},
      selectedValues: {},
      tutorials: { data: [], count: 0, isOpen: false, callOnEnd: () => { } },
      isTutorialStart: false,
      highlight: {
        position: 'relative',
        zIndex: 1000,
        backgroundColor: 'white',
      },
      scrollStatus: false,
    }
  }

  // State Update Methods
  setCoin = (coin: number, refill: boolean = false) => {
    if (refill) {
      if (this.state.userCoinAmount != coin) {
        this.setState(prev => ({ userCoinAmount: prev.userCoinAmount + coin }));
      }
    } else this.setState({ userCoinAmount: coin })
  }

  setUserProfileData = (userData: any) => {
    this.setState({ userProfileData: userData })
  }

  getUserYCoins = () => {
    const { userCoinAmount } = this.state;
    return userCoinAmount;
  }

  changeCreateRoomModal = (condition: boolean, selectedData?: any) => {
    if (this.state.createRoomModal && this.state.selectedValues?.selectedTopic !== selectedData?.selectedTopic) {
      this.setState({ createRoomModal: false }, () => {
        this.setState({ createRoomModal: condition })
      })
    } else {
      this.setState({ createRoomModal: condition })
    }
    if (selectedData) {
      this.setState({ selectedValues: selectedData })
    } else {
      this.setState({ selectedValues: null })
    }
  }


  // Dashboard turtorials
  setTutorials = (data: any[], onEnd: () => void, isOpen = true) => {
    this.setState({ isTutorialStart: isOpen, tutorials: { data, count: 0, isOpen: isOpen, callOnEnd: onEnd } })
  }

  onNextTutorial = () => {
    const { tutorials: { data, count } } = this.state;

    if (count >= data.length - 1) {
      // call api to update tutorial status
      console.log('call api to update tutorial status');
      this.state.tutorials.callOnEnd();

      this.setState(prev => ({ isTutorialStart: false, tutorials: { ...prev.tutorials, data, isOpen: false } }))
      return;
    }

    this.setState(prev => ({ tutorials: { ...prev.tutorials, isOpen: false } }), () => {
      setTimeout(() => {
        this.setState(prev => ({ tutorials: { ...prev.tutorials, count: prev.tutorials.count + 1, isOpen: true } }))
      }, 500);
    });
  }

  setScrollStatus = (status: boolean) => {
    this.setState({ scrollStatus: status });
  }

  render() {
    const { children } = this.props;
    const { userCoinAmount, userProfileData, tutorials, createRoomModal, selectedValues, highlight, isTutorialStart,
      scrollStatus
     } = this.state;
    const { setCoin, setUserProfileData, getUserYCoins, setTutorials, onNextTutorial, changeCreateRoomModal,
      setScrollStatus,
     } = this;

    return (
      <StateContext.Provider value={{
        selectedValues, userCoinAmount, createRoomModal, changeCreateRoomModal, userProfileData, setCoin,
        setUserProfileData, getUserYCoins, tutorials, setTutorials, onNextTutorial, highlight, isTutorialStart,
        scrollStatus, setScrollStatus, 
      }}>
        {children}
      </StateContext.Provider>
    )
  }
}

export default StateContext;
// Customizable Area End